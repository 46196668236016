<template>
  <v-app id="inspire">
    <router-view/>
  </v-app>
</template>

<script>


export default {
  name: 'App',

  data: () => ({
    //
  }),

  created(){
    //this.$hostname = window.location.hostname;

    if(this.$hostname == "mnccom-reg.com"){
      document.title = "M&C 접수페이지";  
    }else if(this.$hostname == "pictcloud.com"){
      document.title = "PICT 접수페이지";  
    }

  },

  mounted(){

    this.loadpage();
    
  },

  methods:{

    loadpage(){

      this.$http.post(this.$host+'/ime/EventName',{
            EVENT_IDX: this.$route.query.eventcode
          , ADMIN_IDX:1
      })
      .then((result)=>{
        document.getElementById('loading').style.display = 'none';
        if(result.data.resultCode == 0){
          this.info = result.data.resultData[0];
          document.title = this.info.NAME;
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        document.getElementById('loading').style.display = 'none';
      });      

    },


  }

  
};
</script>


<style>

  /* 다이얼로그 하단 버튼 */
  .dialog_btn {
    color: #ffffff !important;
    background-color: #4e6ef7 !important;
    font-size: 16px !important;
    width: 94px !important; 
    height: 30px !important;
  }

  /* 다이얼로그 하단 버튼 */
  .dialog_cancel_btn {
    color: #000000 !important;
    background-color: #e5e5e5 !important;
    font-size: 16px !important;
    width: 94px !important; 
    height: 30px !important;
  }


</style>

<!-- 전체 폰트 -->
<style lang="scss">

//로컬폰트 로드
  @font-face {
    font-family: 'AppleSDGothicNeoM';
    src: local('AppleSDGothicNeoM'), url("./assets/fonts/AppleSDGothicNeoM.ttf") format("truetype");
  }

  //로컬폰트 로드
  @font-face {
    font-family: 'NotoSansKR-Regular';
    src: local('NotoSansKR-Regular'), url("./assets/fonts/NotoSansKR-Regular.otf");
  }

  //로컬폰트 로드
  @font-face {
    font-family: 'NotoSansKR-Medium';
    src: local('NotoSansKR-Medium'), url("./assets/fonts/NotoSansKR-Medium.ttf");
  }

  @font-face {
    font-family: 'Inter';
    src: url("https://rsms.me/inter/inter.css");
  }

  //구글폰트
  //@import url('https://fonts.googleapis.com/css2?family=Nanum+Brush+Script&display=swap');
  //$font-family: 'Nanum Brush Script', cursive;

  //전체 폰트 반영
  $font-family: 'NotoSansKR-Regular';
  //$font-family: 'Inter';
  .v-application {
    .headline,
    [class*='display-'],
    [class*='text-'] {
      color: #36405a;
      font-family: $font-family, sans-serif !important;
    }
    font-family: $font-family, sans-serif !important;
  }

  /* v-select 선택값 텍스트 컬러 */
  .v-select__selection.v-select__selection--comma {
    color: #627BFF !important; /* 원하는 색상으로 변경 */
  }



</style>
