<template>
  <v-app>
    <!-- 페어패스 소비자 랜딩 페이지인데 현재는 페어패스 월드로 넘겨주기만한다. -->
    <v-navigation-drawer
      v-model="drawer"
      temporary
      color="#122d3e"
      fixed
      >
      <v-list
        nav
        dense
        >
        <v-list-item-group
          v-model="group"
          active-class="border"
          >
          <v-list-item>
            <v-list-item-title style="color:white" @click="home">HOME</v-list-item-title>
          </v-list-item>
          <v-list-item v-if="this.$hostname == 'fairpass.co.kr'">
            <v-list-item-title  style="color:white" @click="$vuetify.goTo('#fair3', {duration:1000, offset:0, easing:'easeInOutCubic'})">페어패스 소개</v-list-item-title>
          </v-list-item>
          <!-- 
          <v-list-item>
            <v-list-item-title style="color:white" @click="$vuetify.goTo('#fair8', {duration:1000, offset:0, easing:'easeInOutCubic'})">요금안내</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title style="color:white" @click="$vuetify.goTo('#fair9', {duration:1000, offset:0, easing:'easeInOutCubic'})">동영상 매뉴얼</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title style="color:white" @click="$vuetify.goTo('#fair10', {duration:1000, offset:0, easing:'easeInOutCubic'})">앱 다운로드</v-list-item-title>
          </v-list-item>
          -->
          <v-list-item>
            <v-list-item-title style="color:white" @click="runjoin">기업전용 회원가입</v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title style="color:white" @click="runadmin">관리자로그인</v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>      

    <v-app-bar app fixed style="background-color:#122d3e">
      <v-app-bar-nav-icon class="hidden-lg-and-up" style="color:white" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-img v-if="this.$hostname == 'fairpass.co.kr'" class="ml-5 mt-7 mb-7" max-width="134" max-height="24" src="../assets/logosmall.svg" @click="home"></v-img>
      <v-spacer/>
      <v-col cols="12" md="10" align="end" class="hidden-md-and-down">
        <v-btn text color="white" @click="home">HOME</v-btn>
        <v-btn v-if="this.$hostname == 'fairpass.co.kr'" text color="white" @click="home">페어패스 소개</v-btn>
        <!-- 
        <v-btn text color="white" @click="$vuetify.goTo('#fair3', {duration:1000, offset:0, easing:'easeInOutCubic'})">페어패스 소개</v-btn>
        <v-btn text color="white" @click="$vuetify.goTo('#fair8', {duration:1000, offset:0, easing:'easeInOutCubic'})">요금안내</v-btn>
        <v-btn text color="white" @click="$vuetify.goTo('#fair9', {duration:1000, offset:0, easing:'easeInOutCubic'})">동영상 매뉴얼</v-btn>
        <v-btn text color="white" @click="$vuetify.goTo('#fair10', {duration:1000, offset:0, easing:'easeInOutCubic'})">앱 다운로드</v-btn>
        -->
        <v-btn class="ml-5" rounded elevation="5" color="#536EEF" style="color:white" @click="runjoin">기업전용 회원가입</v-btn>
        <v-btn class="ml-5 mr-10" rounded elevation="5" color="#536EEF" style="color:white" @click="runadmin">관리자로그인</v-btn>
      </v-col>
    </v-app-bar>
    
    <v-main style="overflow:hidden">
    <v-container fluid>
    <v-row v-if="this.$hostname == 'fairpass.co.kr'" align="center" justify="center" class="mt-10 d-flex flex-wrap-reverse">
      <v-col cols="12" md="2" class="pa-0 ma-0 hidden-md-and-up"></v-col>
      <v-col cols="12" md="4" class="pa-0 ma-0 .order-2"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-5' : '' "
          :align="$vuetify.breakpoint.smAndDown ? 'center' : 'left' ">
          <img
            :width="$vuetify.breakpoint.smAndDown ? '40%' : '60%'"
            :src="require('../assets/logo-dark.png')"
            />
          <div class="mt-5 text-md-h4 text-sm-h5" 
              :style="$vuetify.breakpoint.smAndDown ? 'text-align:center':'text-align:left'">전시 박람회 컨퍼런스 입장권<br>등록-결제-발급 올인원 프로세스<br>페어패스</div>
          <v-btn class="mt-5" width="200px" height="50" elevation="1" color="#536EEF" style="color:white" 
                 @click="home"><span class="text-h6" style="color:white;">자세히 알아보기</span></v-btn>
      </v-col>
      <v-col cols="12" md="4" class="pa-0 ma-0 .order-1" align="center">
          <v-img
            width="70%"
            :aspect-ratio="910/1594"
            :src="require('../assets/main1.png')"
            >
          </v-img>
      </v-col>
      <v-col cols="12" md="2" class="pa-0 ma-0 hidden-md-and-up"></v-col>
    </v-row>

    <v-row v-if="this.$hostname == 'mnccom-reg.com'" align="center" justify="center" class="mt-10 d-flex flex-wrap-reverse">
      <v-col cols="12" md="6" class="pa-0 ma-0 .order-1" align="center">
        <v-img
          width="70%"
          :aspect-ratio="1046/542" class="mt-15"
          :src="require('../assets/mnclogo2.png')"
          >
        </v-img>
      </v-col>
    </v-row>
    <v-row v-if="this.$hostname == 'pictcloud.com'" align="center" justify="center" class="mt-10 d-flex flex-wrap-reverse">
      <v-col cols="12" md="6" class="pa-0 ma-0 .order-1" align="center">
        <v-img
          width="70%"
          :aspect-ratio="1046/542" class="mt-15"
          :src="require('../assets/pictlogo.png')"
          >
        </v-img>
      </v-col>
    </v-row>


    <v-row class="mt-15 pt-15">
    </v-row>
    
    <!-- 
    <v-row >
      <v-col cols="12" class="pa-0 ma-0">
          <v-img
            :aspect-ratio="1721/986"
            :src="require('../assets/cimage2.jpg')"
            >
            <v-row style="height:100%" justify="center" align="end">
              <v-col cols="6" md="3" align="center">
                <v-btn width="90%" rounded elevation="5" color="#536EEF" style="color:white" @click="runjoin">기업전용 회원가입</v-btn>
              </v-col>
              <v-col cols="6" md="3" align="center">
                <v-btn width="90%" rounded elevation="5" color="#536EEF" style="color:white" @click="runadmin">관리자로그인</v-btn>
              </v-col>
            </v-row>
          </v-img>
      </v-col>
    </v-row>

    <v-row >
      <v-col align="center" cols="12" class="pa-0">
        <v-row justify="center" >
          <img id="fair3" src="../assets/fairpass_3.jpg" style="width:100%;"/>
        </v-row>
        <v-row justify="center" >
          <img id="fair4" src="../assets/fairpass_4.jpg" style="width:100%;"/>
        </v-row>
        <v-row justify="center" >
          <img id="fair5" src="../assets/fairpass_5.jpg" style="width:100%;"/>
        </v-row>
        <v-row justify="center" >
          <img id="fair6" src="../assets/fairpass_6.jpg" style="width:100%;"/>
        </v-row>
        <v-row justify="center" >
          <img id="fair7" src="../assets/fairpass_7.jpg" style="width:100%;"/>
        </v-row>
      </v-col>
    </v-row>
    -->
    
    <div style="position:fixed;width:100%;top:92%;text-align:right">
      <div id="kakao-talk-channel-chat-button" style="margin-right:20px"></div>
    </div>

      </v-container>

    </v-main>

  </v-app>
  
</template>

<script src="https://developers.kakao.com/sdk/js/kakao.js"></script>
<script>

export default {
    data: () => ({
      drawer:false,
      group: null,
      items: [
          {
            src: require('../assets/cimage1.jpg'),
          },
          {
            src: require('../assets/cimage1.jpg'),
          },
          {
            src: require('../assets/cimage1.jpg'),
          },
          {
            src: require('../assets/cimage1.jpg'),
          },
      ],

    }),

    mounted(){
      window.Kakao.Channel.createChatButton({
          container: '#kakao-talk-channel-chat-button',
          channelPublicId: '_atxlNb'
      });
    },

    watch: {
      group () {
        this.drawer = false
      },
    },

    methods: {

      home(){
        //this.$vuetify.goTo(0, {duration:1000, offset:0, easing:"easeInOutCubic"});
        //window.open("https://fairpass.world", "_blank");  
        if(this.$hostname == 'mnccom-reg.com'){
          location.href='https://mnccom-reg.com';
        }else if(this.$hostname == 'pictcloud.com'){
          location.href='https://pictcloud.com';
        }else{
          location.href='https://fairpass.world';
          
        }

        
      },

      test8(){
        this.$vuetify.goTo("#test8", {duration:1000, offset:0, easing:"easeInOutCubic"});
      },

      runjoin(){
        //window.location.href = "http://naver.com";
        if(this.$hostname == 'mnccom-reg.com'){
          window.open("http://admin.mnccom-reg.com/Join?PARENT_COM_IDX=126", "_blank"); 
        }else if(this.$hostname == 'pictcloud.com'){
          window.open("http://admin.pictcloud.com/Join?PARENT_COM_IDX=63", "_blank"); 
        }else{
          window.open("http://admin.fairpass.co.kr/Join", "_blank");  
        }
        
      },

      runadmin(){
        if(this.$hostname == 'mnccom-reg.com'){
          window.open("http://admin.mnccom-reg.com/Login", "_blank");
        }else if(this.$hostname == 'pictcloud.com'){
          window.open("http://admin.pictcloud.com/Login", "_blank");
        }else{
          window.open("http://admin.fairpass.co.kr/Login", "_blank");
        }
      },

    }
}
</script>

<!-- scoped가 있으면 해당 컴포넌트에서만 스타일이 반영됨 -->
<style scoped>
.border {
  border: 2px dashed orange;
}
</style>