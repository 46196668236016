<template>
<v-container fluid class="pa-0">

  <!-- 결제모듈 -->
  <v-dialog persistent scrollable overlay-color="#00000040" v-model="payment_dialog" 
    width="450" 
    :fullscreen="$vuetify.breakpoint.smAndDown ? true : false">
    <v-card style="overflow:hidden">
      <v-card-title style="background:#5d5fef">
        <v-row justify="center" class="pa-1 pb-2">
          <span :style="$vuetify.breakpoint.smAndDown ? 'font-size:14px;color:white': 'font-size:18px;color:white;align:center'"
            >
            {{$t('runeventinfo_label35')}}
          </span>
        </v-row>
      </v-card-title>
      <v-card-text class="ma-0 pl-5 pr-5 pt-3 d-flex justify-center align-center" >

        <v-row style="overflow:hidden" >

          <v-col cols="12" align="center" v-if="visitor != null && visitor.USE_WON == 1">
              {{$t('runeventinfo_label37')}}
              <v-btn height="50" color="white" class="ma-3" >
                <img
                  width="30%"
                  @click="checkpayment(0)"
                  :src="require('@/assets/payicon_toss.png')"
                  />
              </v-btn>
          </v-col>

          <!-- 엑심베이 버튼 -->
          <v-col cols="12" align="center" v-if="visitor != null && visitor.USE_USD == 1">
            {{$t('runeventinfo_label38')}}<br>
            {{$t('runeventinfo_label39')}}<br>
            {{$t('runeventinfo_label40')}}
            <v-btn  height="50" color="white" class="ma-3" >
              <img
                width="30%"
                @click="checkpayment(1)"
                :src="require('@/assets/payicon_exim.png')"
                />
            </v-btn>                  
          </v-col>
          
        </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row justify="center" class="mb-5">
        <v-col cols="10" align="center">
          <v-btn width="90%" outlined @click="payment_dialog = false" large>{{$t('common_msg4')}}</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
    </v-card>
  </v-dialog>  
  

  <!-- 공지사항 상세내용 -->
  <v-dialog persistent scrollable overlay-color="#00000040" 
      v-model="dialog" :fullscreen="$vuetify.breakpoint.smAndDown" width="700">

    <v-card style="overflow:hidden">
      <table style="width:100%">
        <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
          <td class="pl-2 pr-2 pl-md-5 pr-md-5" style="border-right-width:0px;color:#4364FF"
              :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc2'">
            {{ `${broad.INDEX}. ${broad.TITLE}` }}
          </td>
          <td class="pl-2 pr-2 pl-md-5 pr-md-5" style="border-left-width:0px;width:200px;color:#4364FF"
              :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_date' : 'tabledesc_date'">
            {{ broad.REG_DATE }}
          </td>
        </tr>
      </table>
    <v-card-text class="ma-0 pl-10 pr-10 pt-5">
      <div v-html="htmlcontent" class="tabledesc"/>
    </v-card-text>

    <v-card-actions class="ma-0 pa-0">
      <button class="bottombtn_pc1" @click="dialog = false">{{$t('common_msg4')}}</button>
    </v-card-actions>
    </v-card>
  </v-dialog>  
  

  <!-- 행사정보 상세 -->
    <v-row justify="end" dense no-gutters class="mt-5 mb-1 ma-0 pa-0 hidden-md-and-up align-center">
      <div class="btncursor mr-3" style="color:#627BFF;font-size:11px" @click="goinfo">
        <homeicon color="#627BFF" :width="10" class="mr-1"/>{{$t('invitation_label34')}}</div>
      <div class="mr-2">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">        
            <div class="btncursor" v-bind="attrs" v-on="on" style="color:#627BFF;font-size:11px">
            <lanicon color="#627BFF" :width="10" class="mr-0"/>
            Language
            </div>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in $lanlist"
              :key="index" @click="lanchange(item)"
              >
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-row>

    <div class="d-flex justify-center" :style="$vuetify.breakpoint.smAndDown ? 'margin-top:0px':'margin-top:95px'">
    
    <div :style="$vuetify.breakpoint.smAndDown ? 'width:100%':'width:700px'" >

      <v-img
        :aspect-ratio="16/9"
        :src="info.IMAGE"
        style="border-radius: 0px;"
        >
      </v-img>

      <div class="hidden-md-and-down" style="width:100%;height:15px;background:#627bff"/>

      <!-- 패딩 -->
      <div :style="$vuetify.breakpoint.smAndDown ? 'padding:0px 10px 0px 10px':'padding:0px'">

        <!-- 행사 타입 -->      
        <div class="text-center" 
            style="background:#FCB346;color:#ffffff;vertical-align;margin-top:17px;"
            :style="$vuetify.breakpoint.smAndDown ? 'width:120px;height:30px;font-size:12px;middle;line-height:30px':'width:212px;height:30px;font-size:14px;middle;line-height:30px'"
          >{{ info.TYPENAME }}</div>

        <v-tabs
          v-model="tab"
          background-color="white" color="#4364FF" 
          @change="tabchange" show-arrows
          >
          <v-tabs-slider color="#ffffff"></v-tabs-slider>
          <v-tab :class="$vuetify.breakpoint.smAndDown ? 'vtab_text_mb' : 'vtab_text'">
            {{$t('invitation_label4')}}
          </v-tab>
          <v-tab v-if="info.USE_NOTICE != undefined && info.USE_NOTICE != null && info.USE_NOTICE == 1" 
                :class="$vuetify.breakpoint.smAndDown ? 'vtab_text_mb' : 'vtab_text'" >
            {{$t('invitation_label5')}}
          </v-tab>
          <v-tab v-if="info.USE_SCHEDULE != undefined && info.USE_SCHEDULE != null &&info.USE_SCHEDULE == 1" 
                :class="$vuetify.breakpoint.smAndDown ? 'vtab_text_mb' : 'vtab_text'" >
            {{$t('invitation_label6')}}
          </v-tab>
          <v-tab v-if="info.USE_GALLARY != undefined && info.USE_GALLARY != null &&info.USE_GALLARY == 1" 
                :class="$vuetify.breakpoint.smAndDown ? 'vtab_text_mb' : 'vtab_text'" >
            {{$t('invitation_label7')}}
          </v-tab>
          <v-tab v-if="info.USE_MAP != undefined && info.USE_MAP != null &&info.USE_MAP == 1" 
                :class="$vuetify.breakpoint.smAndDown ? 'vtab_text_mb' : 'vtab_text'" >
            {{$t('invitation_label8')}}
          </v-tab>

        <!-- 행사정보 -->    
        <v-tab-item>

          <v-row no-gutters dense class="mt-md-3" align="center" justify="center">
            <v-col cols="12" align="start">
              <label 
                :class="$vuetify.breakpoint.smAndDown ? 'event_title_mb' : 'event_title'"
                >{{info.NAME}}</label>
            </v-col>
            <v-col cols="12" align="start" v-if="info.DESC1 != undefined && info.DESC1 != null && info.DESC1.length > 0" 
                :style="$vuetify.breakpoint.smAndDown ? 'margin-top:10px;' : 'margin-top:25px;'">
              <div
                :class="$vuetify.breakpoint.smAndDown ? 'event_desc1_mb' : 'event_desc1'"
                >{{ info.DESC1 }}</div>
            </v-col>
          </v-row>
          
          <!-- 포스터 -->
          <template v-if="info.USE_POSTER == 1 && info.POSTER != null && info.POSTER != undefined && info.POSTER.length > 0 ">
            <v-row no-gutters dense align="center" justify="center" class="mt-5 mt-md-5 mb-md-4">
              <v-img
                :src="info.POSTER"
                class="ma-0 ma-md-2 btncursor"
                @click="clickimg(info.POSTER)"
                >
              </v-img>
            </v-row>
          </template>

        <!-- 행사정보 테이블 -->
        <template v-if="info.USE_INFO == 1 ">

          <v-row v-if="info.DESC2 != undefined && info.DESC2 != null && info.DESC2.length > 0" dense align="center" justify="center" style="margin-top:15px;margin-right:15px">
              <v-col cols="12" align="start">
                <div
                :class="$vuetify.breakpoint.smAndDown ? 'event_desc2_mb' : 'event_desc2'"
                >{{ info.DESC2 }}</div>
              </v-col>
          </v-row>
          
          <v-row no-gutters dense align="center" justify="center" style="margin-top:25px">
              <v-col cols="12" align="center">
          
                <table style="width:100%;">

                  <tr  style="border-top-left-radius:0px;" 
                      :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'"
                    >
                    <td class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                        :style="$vuetify.breakpoint.smAndDown ? 'border-top-width:1px' : 'border-top-width:1px'"
                        style="border-top-left-radius:0px;">{{$t('invitation_label9')}}</td>
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'"
                        :style="$vuetify.breakpoint.smAndDown ? 'border-top-width:1px' : 'border-top-width:1px'"
                        style="border-top-right-radius:0px;background:#ffffff;">{{info.NAME}}</td>
                  </tr>
                  <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                    <td class="text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                      >{{$t('invitation_label10')}}</td>
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'"
                      >{{ info.EVENT_START == info.EVENT_END ? info.EVENT_START : info.EVENT_START+' ~ ' + info.EVENT_END }}</td>
                  </tr>
                  <template v-if="info.AREANAME != undefined && info.AREANAME != null && info.AREANAME.length > 0">
                  <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                    <td class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'">
                      {{$t('invitation_label11')}}</td>
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'" >{{info.AREANAME}} {{info.ADDR2 != null ? info.ADDR2  : ''}}</td>
                  </tr>
                  </template>
                  <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                    <td class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                    >{{$t('invitation_label12')}}</td>
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'" >{{info.ADDR1 != null ? info.ADDR1 : ''}}</td>
                  </tr>
                  <template v-if="info.HOST != undefined && info.HOST != null && info.HOST.trim().length > 0">
                  <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                    <td class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                    >{{$t('invitation_label13')}}</td>
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'" style="white-space:pre-wrap" >{{info.HOST}}</td>
                  </tr>
                  </template>
                  <template v-if="info.ORGANIZER != undefined && info.ORGANIZER != null && info.ORGANIZER.trim().length > 0">
                  <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                    <td class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                      >{{$t('invitation_label14')}}</td>
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'" style="white-space:pre-wrap" >{{info.ORGANIZER}}</td>
                  </tr>
                  </template>

                  <template v-if="info.SPONSOR != undefined && info.SPONSOR != null && info.SPONSOR.trim().length > 0">
                    <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                      <td class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                      >{{$t('invitation_label38')}}</td>
                      <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'" style="white-space:pre-wrap" >{{info.SPONSOR}}</td>
                    </tr>
                  </template>
  

                  <template v-if="info.INFO != undefined && info.INFO != null && info.INFO.trim().length > 0">
                  <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                    <td class="text-center tabletitle" style="border-bottom-left-radius:0px;"
                      :style="$vuetify.breakpoint.smAndDown ? 'border-bottom-width:1px' : 'border-bottom-width:1px'"
                      :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                      >{{$t('invitation_label15')}}</td>
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'tabledesc'" 
                      :style="$vuetify.breakpoint.smAndDown ? 'border-bottom-width:1px' : 'border-bottom-width:1px'"
                      style="border-bottom-right-radius:0px;white-space:pre-wrap;">{{info.INFO == undefined||info.INFO == null?'':info.INFO}}</td>
                  </tr>
                  </template>


                </table>

          
              </v-col>
          </v-row>
        </template>


        <!-- 등록기간 안내 -->
        <template v-if="info.USE_REGDATE == 1 && optionlist != undefined && optionlist.length > 0">

          <v-row no-gutters dense align="center" justify="center" 
            :style="$vuetify.breakpoint.smAndDown ? 'margin-top:25px' : 'margin-top:50px'">
            <v-col cols="12" align="center">
        
              <table style="width:100%;border-collapse: collapse;">

                <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                  <td class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                      :style="$vuetify.breakpoint.smAndDown ? 'border-top-width:1px;font-weight:bold' : 'border-top-width:1px;font-weight:bold'"
                      >{{$t('invitation_label16')}}</td>
                </tr>

                <tr v-for="option in optionlist" :key="option.IDX" 
                    :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5" 
                        :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md2' : 'tabledesc2'"
                        >
                      {{ option.NAME }}
                      <span style="color:#627BFF;margin-left:5px">{{ option.COST_TYPE == 0 ? $t('invitation_label35') : $t('invitation_label36') }}</span><br>
                      {{ $t('invitation_label17') + option.REG_START + "~" + option.REG_END }}
                    </td>
                </tr>
              </table>
        
            </v-col>
          </v-row>
        </template>

        <!-- 환불정보 -->
        <template v-if="info.USE_REFUND ==1 && refundlist != undefined && refundlist.length > 0">

          <v-row no-gutters dense align="center" justify="center" 
            :style="$vuetify.breakpoint.smAndDown ? 'margin-top:25px' : 'margin-top:50px'">
            <v-col cols="12" align="center">
        
              <table style="width:100%;border-collapse: collapse;">

                <tr :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                  <td colspan="3" class="ma-0 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tabletitle_mb' : 'tabletitle'"
                      :style="$vuetify.breakpoint.smAndDown ? 'border-top-width:1px;font-weight:bold' : 'border-top-width:1px;font-weight:bold'"
                      >{{$t('invitation_label18')}}</td>
                </tr>

                <tr v-for="refund in refundlist" :key="refund.IDX" 
                    :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tablerefund_md' : 'tabledesc2'">
                        {{ refund.DAY }}
                    </td>
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tablerefund_md' : 'tabledesc2'">
                      {{ refund.RATE }}
                    </td>
                    <td class="pl-2 pr-2 pl-md-5 pr-md-5 text-center" :class="$vuetify.breakpoint.smAndDown ? 'tablerefund_md' : 'tabledesc2'">
                      {{ refund.REFUND }}
                    </td>
                </tr>
              </table>
        
            </v-col>
          </v-row>
        </template>

        <!-- 하단 지도 및 공유 버튼 -->
          <v-row no-gutters dense align="center" justify="center" class="d-flex justify-space-around" 
            :style="$vuetify.breakpoint.smAndDown ? 'margin-top:25px' : 'margin-top:50px'">
            <template v-if="info.LINK_URL != null && info.LINK_URL.length > 0">
              <div class="d-flex justify-center align-center btncursor" @click="showlink(info.LINK_URL)"
                :class="$vuetify.breakpoint.smAndDown ? 'text_normal_mb' : 'text_normal_pc'">
                <homeblackicon class="mr-2" />
                {{$t('invitation_label19')}}
              </div>
            </template>
            <template v-if="info.YOUTUBE != null && info.YOUTUBE.length > 0" >
              <div class="d-flex justify-center align-center btncursor" @click="showlink(info.YOUTUBE)"
                :class="$vuetify.breakpoint.smAndDown ? 'text_normal_mb' : 'text_normal_pc'">
                <youtubeicon class="mr-2" />
                YOUTUBE
              </div>
            </template>
            <template v-if="info.LATITUTE != null && info.LATITUTE.length > 0 && info.LONGITUTE != null && info.LONGITUTE.length > 0" >
              <div class="d-flex justify-center align-center btncursor" @click="showmap"
                :class="$vuetify.breakpoint.smAndDown ? 'text_normal_mb' : 'text_normal_pc'">
                <mapicon class="mr-2" />
                {{$t('invitation_label20')}}
              </div>
            </template>
            <template align="center">
              <div class="d-flex justify-center align-center btncursor" @click="sharekakao()"
                :class="$vuetify.breakpoint.smAndDown ? 'text_normal_mb' : 'text_normal_pc'">
                <shareicon class="mr-2" />
                {{$t('invitation_label37')}}
              </div>
            </template>
          </v-row>

          <v-row style="height:300px">
          </v-row>


          <!-- 모바일에서 옵션 선택 영역 -->
          <v-bottom-navigation
            class="pa-0 ma-0 hidden-md-and-up"
            app
            color="indigo" 
            style="display:block;background:#f6f6f6;overflow-y: auto;"
            height="auto" 
            >
            <template v-if="visitor == null"> <!-- 등록정보 없는 상태 -->
              <v-row class="ma-0 pa-0 mt-2 mb-2 pl-2 pr-2" justify="center">

                <!-- 내국인/외국인 선택 -->
                <v-col cols="12" align="center" class="ma-0 pa-0" v-if="info.USE_LOCAL == 1 && info.USE_FOREIGNER == 1">
                  <v-select  
                    id="SELECT_NATION" hide-details :menu-props="{ origin: 'top', offsetY: true }"
                    v-model="SELECT_NATION" :items="nationlist" outlined single-line
                    :placeholder="this.$t('runeventinfo_label36')"
                    style="border-radius:0px;border:1px #E5E5E5 solid; background:white; font-size:14px;"
                    item-text="NAME" item-value="IDX">
                    <template v-slot:append>
                      <v-icon color="#4364FF">mdi-triangle-down</v-icon>
                    </template>
                    <template v-slot:label>
                        <v-list-item-title class="select_text">
                          {{ $t('runeventinfo_label36') }}
                        </v-list-item-title>
                    </template>                
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title class="select_text">
                          {{ data.item.NAME }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>                
                  </v-select>
                </v-col>

                <v-col cols="12" align="center" class="ma-0 pa-0 mt-2">
                  <v-select  
                    id="SELECT_OPTION" hide-details :menu-props="{ origin: 'top', offsetY: true }"
                    v-model="SELECT_OPTION" :items="optionlist" outlined single-line
                    :placeholder="this.$t('runeventinfo_label1')"
                    style="border-radius:0px;border:1px #E5E5E5 solid; background:white; font-size:14px;"
                    item-text="NAME" item-value="IDX">
                    <template v-slot:append>
                      <v-icon color="#4364FF">mdi-triangle-down</v-icon>
                    </template>
                    <template v-slot:label>
                        <v-list-item-title class="select_text">
                          {{ $t('runeventinfo_label1') }}
                        </v-list-item-title>
                    </template>                
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title class="select_text">
                          {{ data.item.NAME }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </template>                
                  </v-select>
                </v-col>
                <v-col cols="6" align="center" class="mt-2 pa-0">
                  <button class="bottombtn"  @click="goregist" >{{this.$t('runeventinfo_label2')}}</button>
                </v-col>
                <v-col cols="6" align="center" class="mt-2 pa-0">
                  <button class="bottombtn2"  @click="runinfo">{{this.$t('runeventinfo_label8')}}</button>
                </v-col>
              </v-row>
            </template>

            <template v-else>

              <template v-if="visitor.STATE == 1"> <!-- 접수 -->

                <v-row class="ma-0 pa-0 mt-1" justify="center">
                  <template v-if="visitor.CURTIME < 0"> <!-- 종료된 행사 -->
                    <v-col cols="12" align="end" class="mt-0 pa-0 pr-3 mb-1">
                      <div style="width:100%;color:#627BFF !important">{{this.$t('runeventinfo_label7')}}</div>
                    </v-col>
                    <v-col cols="12" align="center" class="ma-0 pa-0">
                      <UserInfo :visitor="visitor"/>
                    </v-col>
                    <v-col cols="12" align="center" class="mt-0 pa-0">
                      <button class="bottombtn" @click="runinfo">{{this.$t('runeventinfo_label8')}}</button>
                    </v-col>
                  </template>
                  <template v-else>
                    <template v-if="visitor.COST_TYPE == 0"> <!-- 무료행사(접수완료) -->
                      <v-col cols="12" align="end" class="mt-0 pa-0 pr-3 mb-1">
                        <label @click="runedit" class="info_blue_text">{{this.$t('runeventinfo_label11')}}</label>
                        <label @click="runcancel" class="info_blue_text ml-5">{{this.$t('runeventinfo_label10')}}</label>
                      </v-col>
                      <v-col cols="12" align="center" class="ma-0 pa-0">
                        <UserInfo :visitor="visitor"/>
                      </v-col>
                      <v-col cols="12" align="center" class="mt-0 pa-0">
                        <button class="bottombtn" @click="goqrticket">{{this.$t('runeventinfo_label9')}}</button>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col cols="12" align="end" class="mt-0 pa-0 pr-3 mb-1">
                        <div style="width:100%;color:#627BFF !important">{{this.$t('runeventinfo_label12')}}</div>
                      </v-col>
                      <v-col cols="12" align="center" class="ma-0 pa-0">
                        <UserInfo :visitor="visitor"/>
                      </v-col>
                      <v-col cols="12" align="center" class="mt-0 pa-0">
                        <button class="bottombtn" @click="showpayment_list" >{{this.$t('runeventinfo_label13')}}</button>
                      </v-col>

                    </template>
                  </template>

                </v-row>
              </template>

              <template v-else-if="visitor.STATE == 2"> <!-- 수납 -->
                <v-row class="ma-0 pa-0 mt-1" justify="center">

                  <template v-if="visitor.CURTIME < 0"> <!-- 종료된 행사 -->
                    <v-col cols="12" align="end" class="mt-0 pa-0 pr-3 mb-1">
                      <div style="width:100%;color:#627BFF !important">{{this.$t('runeventinfo_label7')}}</div>
                    </v-col>
                    <v-col cols="12" align="center" class="ma-0 pa-0">
                      <UserInfo :visitor="visitor"/>
                    </v-col>
                    <v-col cols="12" align="center" class="mt-0 pa-0">
                      <button class="bottombtn" @click="runinfo">{{this.$t('runeventinfo_label8')}}</button>
                    </v-col>
                  </template>
                  <template v-else>
                    <v-col cols="12" align="end" class="mt-0 pa-0 pr-3 mb-1">
                      <label @click="checkcancelpayment" class="info_blue_text">{{this.$t('runeventinfo_label10')}}</label>
                      <label @click="runedit" class="info_blue_text ml-5">{{this.$t('runeventinfo_label11')}}</label>
                    </v-col>
                    <v-col cols="12" align="center" class="ma-0 pa-0">
                      <UserInfo :visitor="visitor"/>
                    </v-col>
                    <v-col cols="12" align="center" class="mt-0 pa-0">
                      <button class="bottombtn" @click="goqrticket">{{this.$t('runeventinfo_label9')}}</button>
                    </v-col>
                  </template>
                </v-row>
              </template>
              <template v-else-if="visitor.STATE == 3"> <!-- 출력완료 -->
                <v-row class="ma-0 pa-0 mt-1" justify="center">
                  <template v-if="visitor.CNT3 > 0"> <!-- 후기설문이 있는 경우 -->
                    <template v-if="visitor.CNT1 > 0 || visitor.CNT2 > 0"> <!-- 설문을 한 경우 -->
                      <v-col cols="12" align="end" class="mt-0 pa-0 pr-3 mb-1">
                      <div style="width:100%;color:#627BFF !important;font-size:14px">{{$t('runeventinfo_label14')}}</div>
                      </v-col>
                      <v-col cols="12" align="center" class="ma-0 pa-0">
                        <UserInfo :visitor="visitor"/>
                      </v-col>
                      <v-col cols="12" align="center" class="mt-0 pa-0">
                        <button class="bottombtn" @click="runinfo">{{this.$t('runeventinfo_label8')}}</button>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col cols="12" align="end" class="mt-0 pa-0 pr-3 mb-1">
                        <div style="width:100%;color:#627BFF;font-size:14px">{{$t('runeventinfo_label15')}}</div>
                      </v-col>
                      <v-col cols="12" align="center" class="ma-0 pa-0">
                        <UserInfo :visitor="visitor"/>
                      </v-col>
                      <v-col cols="12" align="center" class="mt-0 pa-0">
                        <button class="bottombtn mt-1" @click="runreview">{{$t('runeventinfo_label16')}}</button>
                      </v-col>
                    </template>
                  </template>
                  <template v-else>
                    <v-col cols="12" align="end" class="mt-0 pa-0 pr-3 mb-1">
                      <div style="width:100%;color:#627BFF">{{$t('runeventinfo_label15')}}</div>
                    </v-col>
                    <v-col cols="12" align="center" class="ma-0 pa-0">
                      <UserInfo :visitor="visitor"/>
                    </v-col>
                    <v-col cols="12" align="center" class="mt-0 pa-0">
                      <button class="bottombtn" @click="runinfo">{{this.$t('runeventinfo_label8')}}</button>
                    </v-col>
                  </template>
                </v-row>            
              </template>
              <template v-else-if="visitor.STATE == 4"> <!-- 관람완료 -->
                <v-row class="ma-0 pa-0 mt-1" justify="center">
                  
                    <template v-if="visitor.CNT3 > 0"> <!-- 후기설문이 있는 경우 -->
                      <template v-if="visitor.CNT1 > 0 || visitor.CNT2 > 0"> <!-- 설문을 한 경우 -->
                        <v-col cols="12" align="end" class="mt-0 pa-0 pr-3 mb-1">
                          <div style="width:100%;color:#627BFF !important">{{$t('runeventinfo_label17')}}</div>
                        </v-col>
                        <v-col cols="12" align="center" class="ma-0 pa-0">
                          <UserInfo :visitor="visitor"/>
                        </v-col>
                        <v-col cols="12" align="center" class="mt-0 pa-0">
                          <button class="bottombtn" @click="runinfo">{{this.$t('runeventinfo_label8')}}</button>
                        </v-col>
                      </template>
                      <template v-else>
                        <v-col cols="12" align="end" class="mt-0 pa-0 pr-3 mb-1">
                          <div style="width:100%;color:#627BFF !important">{{$t('runeventinfo_label18')}}</div>
                        </v-col>
                        <v-col cols="12" align="center" class="ma-0 pa-0">
                          <UserInfo :visitor="visitor"/>
                        </v-col>
                        <v-col cols="12" align="center" class="mt-0 pa-0">
                          <button class="bottombtn" @click="runreview" >{{$t('runeventinfo_label16')}}</button>
                        </v-col>
                      </template>
                    </template>
                    <template v-else>
                      <v-col cols="12" align="end" class="mt-0 pa-0 pr-3 mb-1">
                        <div style="width:100%;color:#627BFF !important">{{$t('runeventinfo_label18')}}</div>
                      </v-col>
                      <v-col cols="12" align="center" class="ma-0 pa-0">
                        <UserInfo :visitor="visitor"/>
                      </v-col>
                      <v-col cols="12" align="center" class="mt-0 pa-0">
                        <button class="bottombtn" @click="runinfo">{{this.$t('runeventinfo_label8')}}</button>
                      </v-col>
                    </template>
                  
                </v-row>            
              </template>
              <template v-else-if="visitor.STATE == 5"> <!-- 취소(무료는 취소, 유료는 환불요청상태) -->
                <v-row class="ma-0 pa-0 mt-1" justify="center">
                  <template v-if="visitor.COST_TYPE == 0">
                    <v-col cols="12" align="end" class="mt-0 pa-0 pr-3 mb-1">
                      <div style="width:100%;color:#627BFF !important;font-size:14px">{{$t('runeventinfo_label19')}}</div>
                    </v-col>
                    <v-col cols="12" align="center" class="ma-0 pa-0">
                      <UserInfo :visitor="visitor"/>
                    </v-col>
                    <v-col cols="12" align="center" class="mt-0 pa-0">
                      <button class="bottombtn" @click="runinfo">{{this.$t('runeventinfo_label8')}}</button>
                    </v-col>
                  </template>
                  <template v-else>
                    <v-col cols="12" align="end" class="mt-0 pa-0 pr-3 mb-1">
                      <div style="width:100%;color:#627BFF !important">{{$t('runeventinfo_label20')}}</div>
                    </v-col>
                    <v-col cols="12" align="center" class="ma-0 pa-0">
                      <UserInfo :visitor="visitor"/>
                    </v-col>
                    <v-col cols="12" align="center" class="mt-0 pa-0">
                      <button class="bottombtn" @click="runinfo">{{this.$t('runeventinfo_label8')}}</button>
                    </v-col>
                  </template>
                </v-row>            
              </template>

              <template v-else-if="visitor.STATE == 6"> <!-- 환불완료(유료) -->
                <v-row class="ma-0 pa-0 mt-1" justify="center">
                  <v-col cols="12" align="end" class="mt-0 pa-0 pr-3 mb-1">
                    <div style="width:100%;color:#627BFF !important">{{$t('runeventinfo_label19')}}</div>
                  </v-col>
                  <v-col cols="12" align="center" class="ma-0 pa-0">
                    <UserInfo :visitor="visitor"/>
                  </v-col>
                  <v-col cols="12" align="center" class="mt-0 pa-0">
                    <button class="bottombtn" @click="runinfo">{{this.$t('runeventinfo_label8')}}</button>
                  </v-col>
                </v-row>            
              </template>

            </template>
            
          </v-bottom-navigation>      

        </v-tab-item>


        <!-- 공지사항 탭 -->
        <v-tab-item v-if="info.USE_NOTICE != undefined && info.USE_NOTICE != null &&info.USE_NOTICE == 1">

          <v-row no-gutters dense align="center" justify="center" class="mt-1 mt-md-5 mb-10">
            <template v-if="boardlist.length > 0">
      
              <v-col cols="12" align="center">
                <table style="width:100%;border-collapse: collapse;">
                  <tr v-for="(item, index) in boardlist" :key="item.IDX" class="btncursor"
                      :style="$vuetify.breakpoint.smAndDown ? 'height:40px;' : 'height:40px;'">
                      <td class="pl-2 pr-2 pl-md-5 pr-md-5" @click="shownotice(index, item)"
                          :style="$vuetify.breakpoint.smAndDown ? 'border-right-width:1px' : 'border-right-width:0px'"
                          :class="$vuetify.breakpoint.smAndDown ? 'table100_md' : 'tabledesc2'">
                        {{ `${index+1}. ${item.TITLE}` }}
                      </td>
                      <td class="pl-2 pr-2 pl-md-5 pr-md-5 hidden-md-and-down" @click="shownotice(index, item)"
                          style="border-left-width:0px;width:200px"
                          :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_date' : 'tabledesc_date'">
                        {{ item.REG_DATE }}
                      </td>
                  </tr>
                </table>
              </v-col>

            </template>
            <template v-else>
              <v-row align="center" justify="center" >
                <v-col  cols="12" align="center">
                  <label style="color:#808080">{{$t('invitation_label24')}}</label>
                </v-col>
              </v-row>
            </template>
          </v-row>
        </v-tab-item>

        <!-- 부대행사 -->
        <v-tab-item v-if="info.USE_SCHEDULE != undefined && info.USE_SCHEDULE != null &&info.USE_SCHEDULE == 1">
          <v-row no-gutters dense align="center" justify="center" class="mt-1 mt-md-5 mb-10">
          <template v-if="schedulelist.length > 0">

            <v-col cols="12" align="center">
              <table style="width:100%;border-collapse: collapse;">
                <tr v-for="item in schedulelist" :key="item.NUMBER">

                  <template v-if="item.SORTTYPE == 1">

                    <td class="pl-2 pr-2 pl-md-5 pr-md-5" 
                        :class="$vuetify.breakpoint.smAndDown ? 'table100_md' : 'tabledesc2'" style="color:#3d3d3d !important;background:#F6F6F6">
                        {{ item.SDATE + " " + daycodeToString(item.DAYCODE) }}
                    </td>

                  </template>
                  <template v-else>
                    <td class="pa-5" :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_date' : 'tabledesc_date'">

                      <div class="mt-1 mb-5" style="font-size:16px;color:#5f67ec;">{{ item.NAME }}</div>
                      <div class="mt-3" :class="$vuetify.breakpoint.smAndDown ? 'table100_md' : 'tabledesc_area'">{{ $t('invitation_label25') + item.START_TIME.substring(0,5) + " ~ " + item.END_TIME.substring(0,5) }}</div>
                      <div class="mb-1" :class="$vuetify.breakpoint.smAndDown ? 'table100_md' : 'tabledesc_area'">{{ $t('invitation_label26') + item.LOCATION }}</div>
                      <div class="mt-5 mb-1" :class="$vuetify.breakpoint.smAndDown ? 'table100_md' : 'tabledesc_area'">{{ item.DESC }}</div>
                    </td>
                  </template>

                </tr>
              </table>
            </v-col>

          </template>
          <template v-else>
            <v-row align="center" justify="center" >
              <v-col  cols="12" align="center">
                <label style="color:#808080">{{$t('invitation_label24')}}</label>
              </v-col>
            </v-row>
          </template>
          </v-row>
        </v-tab-item>

        <!-- 갤러리 -->
        <v-tab-item v-if="info.USE_GALLARY != undefined && info.USE_GALLARY != null &&info.USE_GALLARY == 1">
          <v-row no-gutters dense align="center" justify="center" class="mt-1 mt-md-5 mb-10">
          <template v-if="gallist.length > 0">

            <v-col cols="12" align="center" class="pa-0 ma-0 mb-10">
              <table style="width:100%;border-collapse: collapse;">
                <tr v-for="item in gallist" :key="item.IDX">
                    <td class="pa-5 pa-md-10 text-center">
                      <img @click="clickimg(item.IMAGE)" width="100%" style="width:100%;max-width:392px" :src="item.IMAGE" />
                    </td>
                </tr>
              </table>
            </v-col>

          </template>
          <template v-else>
            <v-row align="center" justify="center" >
              <v-col  cols="12" align="center">
                <label style="color:#808080">{{$t('invitation_label24')}}</label>
              </v-col>
            </v-row>
          </template>
          </v-row>
        </v-tab-item>

        <!-- 오시는길 -->
        <v-tab-item v-if="info.USE_MAP != undefined && info.USE_MAP != null &&info.USE_MAP == 1">
          <v-row no-gutters dense align="center" justify="center" class="mt-1 mt-md-5 mb-10">
          <template v-if="maplist.length > 0">

            <v-col cols="12" align="center" class="pa-0 ma-0 mb-10">
              <table style="width:100%;border-collapse: collapse;">
                <tr v-for="item in maplist" :key="item.IDX">
                    <td class="pa-5" >

                      <div class="mt-0 mb-3"
                        :class="$vuetify.breakpoint.smAndDown ? 'event_map_title' : 'event_map_title'">
                        {{ item.TITLE }}
                      </div>
                      
                      <img @click="clickimg(item.IMAGE)" width="100%" style="width:100%" :src="item.IMAGE" />
                      

                      <div class="mt-3 d-flex align-center" style="background:#f6f6f6;padding: 10px 10px 10px 10px">
                        <div :class="$vuetify.breakpoint.smAndDown ? 'tabledesc_md' : 'event_desc2'" class="me-auto">{{ item.DESC }}</div>
                        <button
                          @click="showlink(item.LINK)" class="mt-3"
                          style="background:#627BFF; color:white; border: 0px solid white;font-size:14px;width:100px;height:30px"
                          v-if="item.LINK != null && item.LINK.length > 0" outlined>
                          {{$t('invitation_label28')}}
                        </button>
                      </div> 

                    </td>
                </tr>
              </table>
            </v-col>

          </template>
          <template v-else>
            <v-row align="center" justify="center" >
              <v-col  cols="12" align="center">
                <label style="color:#808080">{{$t('invitation_label24')}}</label>
              </v-col>
            </v-row>
          </template>
          </v-row>
        </v-tab-item>

        </v-tabs>

      </div>
    </div>

    <!-- pc 옵션선택 영역 -->
    <div style="width:455px;margin-left:30px" class="hidden-sm-and-down" >

        <v-card
          ref="info_card"
          color="#ffffff"
          class="pb-15 fixed_info_150"
          :style="'top:'+topscroll+'px !important'"
          elevation="0"
          >
        
          <v-row justify="center" dense no-gutters class="ma-0 pa-0">
            <v-col cols="6">
              <div class="top_blue_btn d-flex justify-center align-center btncursor" @click="goinfo"><homeicon class="mr-3"/>{{$t('invitation_label34')}}</div>
            </v-col>
            <v-col cols="6">
              <div class="top_blue_btn d-flex justify-center align-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">        
                    <lanicon class="mr-3"/>
                    <a v-bind="attrs" v-on="on" style="color:#ffffff">Language</a>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in $lanlist"
                      :key="index" @click="lanchange(item)"
                      >
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-col>
          </v-row>

          <v-row style="height:54px"></v-row>
          <v-row dense no-gutters>
            <v-col cols="12" style="background:#F6F6F6" class="pt-8">

              <v-row justify="center">
                <v-col cols="12">
                  <div class="text-center option_title">{{ info.NAME }}</div>
                </v-col>
              </v-row>

          <!-- 등록정보 없는 상태 -->
          <template v-if="visitor == null"> 

            <v-row justify="center">

              <v-col md="10" align="center" class="ma-0 pa-0 mt-5 mb-4" v-if="info.USE_LOCAL == 1 && info.USE_FOREIGNER == 1">
                <v-select 
                  color="#4364FF"  id="SELECT_NATION" hide-details :menu-props="{ origin: 'bottom', offsetY: true }"
                  v-model="SELECT_NATION" :items="nationlist" outlined single-line
                  style="border-radius:0px;background:white;font-size:14px;"
                  item-text="NAME" item-value="IDX" 
                  >
                  <template v-slot:append>
                    <v-icon color="#4364FF">mdi-triangle-down</v-icon>
                  </template>
                  <template v-slot:label>
                      <v-list-item-title class="select_text">
                        {{ $t('runeventinfo_label36') }}
                      </v-list-item-title>
                  </template>                
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title class="select_text">
                        {{ data.item.NAME }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>                
                </v-select>
              </v-col>

              <v-col md="10" align="center" class="ma-0 pa-0 mt-1 mb-4">
                <v-select 
                  color="#4364FF"  id="SELECT_OPTION" hide-details :menu-props="{ origin: 'bottom', offsetY: true }"
                  v-model="SELECT_OPTION" :items="optionlist" outlined single-line
                  style="border-radius:0px;background:white;font-size:14px;"
                  item-text="NAME" item-value="IDX" 
                  >
                  <template v-slot:append>
                    <v-icon color="#4364FF">mdi-triangle-down</v-icon>
                  </template>
                  <template v-slot:label>
                      <v-list-item-title class="select_text">
                        {{ $t('runeventinfo_label1') }}
                      </v-list-item-title>
                  </template>                
                  <template v-slot:item="data">
                    <v-list-item-content>
                      <v-list-item-title class="select_text">
                        {{ data.item.NAME }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>                
                </v-select>
              </v-col>

              <!-- 하단 버튼 -->
              <v-col md="12" align="center" class="d-flex">
                <button class="bottombtn_pc1" @click="goregist">{{$t('runeventinfo_label2')}}</button>
                <button class="bottombtn_pc2" @click="runinfo">{{$t('runeventinfo_label3')}}</button>
              </v-col>

            </v-row>

          </template>
          <!-- 등록정보 있는 상태 -->
          <template v-else>

            <template v-if="visitor.STATE == 1"> <!-- 접수 -->

              <v-row class="mt-3" justify="center">

                <template v-if="visitor.CURTIME < 0"> <!-- 종료된 행사 -->
                  <v-col cols="11" align="end" class="mt-0 pa-0">
                    <div style="width:100%;color:#627BFF !important" class="text_normal_pc">{{this.$t('runeventinfo_label7')}}</div>
                  </v-col>
                  <v-col cols="12" align="center" class="mt-0 pt-0 pb-0">
                    <UserInfo :visitor="visitor"/>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <button class="bottombtn_pc1" @click="runinfo">{{this.$t('runeventinfo_label8')}}</button>
                  </v-col>
                </template>
                <template v-else>
                  <template v-if="visitor.COST_TYPE == 0"> <!-- 무료행사(접수완료) -->

                    <v-col cols="11" align="end" class="mt-0 pa-0">
                      <v-btn @click="runcancel" text class="gray_text">{{this.$t('runeventinfo_label10')}}</v-btn>
                      <v-btn @click="runedit" text class="gray_text ml-5">{{this.$t('runeventinfo_label11')}}</v-btn>
                    </v-col>

                    <v-col cols="12" align="center" class="mt-0 pt-0 pb-0">
                      <UserInfo :visitor="visitor"/>
                    </v-col>

                    <v-col cols="12" class="pt-0">
                      <button class="bottombtn_pc1" @click="goqrticket">{{$t('runeventinfo_label9')}}</button>
                    </v-col>

                  </template>
                  <template v-else>
                    <v-col cols="11" align="end" class="mt-0 pa-0">
                      <div style="width:100%;color:#627BFF !important" class="text_normal_pc">{{this.$t('runeventinfo_label12')}}</div>
                    </v-col>
                    <v-col cols="12" align="center" class="mt-0 pt-0 pb-0">
                      <UserInfo :visitor="visitor"/>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <button class="bottombtn_pc1" @click="showpayment_list" >{{this.$t('runeventinfo_label13')}}</button>
                    </v-col>

                  </template>
                </template>

              </v-row>
            </template>

            <template v-else-if="visitor.STATE == 2"> <!-- 수납 -->
              <v-row class="mt-3" justify="center">

                <template v-if="visitor.CURTIME < 0"> <!-- 종료된 행사 -->
                  <v-col cols="11" align="end" class="mt-3 pa-0 mb-1">
                    <div style="width:100%;color:#627BFF !important">{{this.$t('runeventinfo_label7')}}</div>
                  </v-col>
                  <v-col cols="12" align="center" class="mt-0 pt-0 pb-0">
                    <UserInfo :visitor="visitor"/>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <button class="bottombtn_pc1" @click="runinfo">{{this.$t('runeventinfo_label8')}}</button>
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="11" align="end" class="mt-3 pa-0 mb-1">
                    <v-btn @click="checkcancelpayment" text  class="text_normal_pc">{{this.$t('runeventinfo_label10')}}</v-btn>
                    <v-btn @click="runedit" text  class="text_normal_pc ml-5">{{this.$t('runeventinfo_label11')}}</v-btn>
                  </v-col>
                  <v-col cols="12" align="center" class="mt-0 pt-0 pb-0">
                    <UserInfo :visitor="visitor"/>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <button class="bottombtn_pc1" @click="goqrticket">{{this.$t('runeventinfo_label9')}}</button>
                  </v-col>
                </template>
              </v-row>
            </template>
            
            <template v-else-if="visitor.STATE == 3"> <!-- 출력완료 -->

              <v-row class="mt-3" justify="center">
                
                  <template v-if="visitor.CNT3 > 0"> <!-- 후기설문이 있는 경우 -->
                    <template v-if="visitor.CNT1 > 0 || visitor.CNT2 > 0"> <!-- 설문을 한 경우 -->
                      <v-col cols="11" align="end" class="mt-3 pa-0 mb-1">
                        <div style="width:100%;color:#627BFF !important" class="text_normal_pc">{{$t('runeventinfo_label14')}}</div>
                      </v-col>
                      <v-col cols="12" align="center" class="mt-0 pt-0 pb-0">
                        <UserInfo :visitor="visitor"/>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <button class="bottombtn_pc1" @click="runinfo">{{this.$t('runeventinfo_label8')}}</button>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col cols="11" align="end" class="mt-3 pa-0 mb-1">
                        <div style="width:100%;color:#627BFF !important" class="text_normal_pc mb-1">{{$t('runeventinfo_label15')}}</div>
                      </v-col>
                      <v-col cols="12" align="center" class="mt-0 pt-0 pb-0">
                        <UserInfo :visitor="visitor"/>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <button class="bottombtn_pc1" @click="runreview">{{$t('runeventinfo_label16')}}</button>
                      </v-col>                      
                    </template>
                  </template>
                  <template v-else>
                    <v-col cols="11" align="end" class="mt-3 pa-0 mb-1">
                      <div style="width:100%;color:#627BFF !important" class="text_normal_pc">{{$t('runeventinfo_label15')}}</div>
                    </v-col>
                    <v-col cols="12" align="center" class="mt-0 pt-0 pb-0">
                      <UserInfo :visitor="visitor"/>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <button class="bottombtn_pc1" @click="runinfo">{{this.$t('runeventinfo_label8')}}</button>
                    </v-col>
                  </template>
                
              </v-row>            
            </template>
            
            <template v-else-if="visitor.STATE == 4"> <!-- 관람완료 -->
              <v-row class="mt-3" justify="center">
                
                  <template v-if="visitor.CNT3 > 0"> <!-- 후기설문이 있는 경우 -->
                    <template v-if="visitor.CNT1 > 0 || visitor.CNT2 > 0"> <!-- 설문을 한 경우 -->
                      <v-col cols="11" align="end" class="mt-3 pa-0 mb-1">
                        <div style="width:100%;color:#627BFF !important" class="text_normal_pc">{{$t('runeventinfo_label17')}}</div>
                      </v-col>
                      <v-col cols="12" align="center" class="mt-0 pt-0 pb-0">
                        <UserInfo :visitor="visitor"/>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <button class="bottombtn_pc1" @click="runinfo">{{this.$t('runeventinfo_label8')}}</button>
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col cols="11" align="end" class="mt-3 pa-0 mb-1">
                        <div style="width:100%;color:#627BFF !important" class="text_normal_pc">{{$t('runeventinfo_label18')}}</div>
                      </v-col>
                      <v-col cols="12" align="center" class="mt-0 pt-0 pb-0">
                        <UserInfo :visitor="visitor"/>
                      </v-col>
                      <v-col cols="12" class="pt-0">
                        <button class="bottombtn_pc1" @click="runreview" >{{$t('runeventinfo_label16')}}</button>
                      </v-col>
                    </template>
                  </template>
                  <template v-else>
                    <v-col cols="11" align="end" class="mt-3 pa-0 mb-1">
                      <div style="width:100%;color:#627BFF !important" class="text_normal_pc">{{$t('runeventinfo_label18')}}</div>
                    </v-col>
                    <v-col cols="12" align="center" class="mt-0 pt-0 pb-0">
                      <UserInfo :visitor="visitor"/>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <button class="bottombtn_pc1" @click="runinfo">{{this.$t('runeventinfo_label8')}}</button>
                    </v-col>
                  </template>
                
              </v-row>            
            </template>
            
            <template v-else-if="visitor.STATE == 5"> <!-- 취소(무료는 취소, 유료는 환불요청상태) -->
              <v-row class="mt-3" justify="center">
                <template v-if="visitor.COST_TYPE == 0">
                  <v-col cols="11" align="end" class="mt-3 pa-0 mb-1">
                    <div style="width:100%;color:#627BFF !important" class="text_normal_pc">{{$t('runeventinfo_label19')}}</div>
                  </v-col>
                  <v-col cols="12" align="center" class="mt-0 pt-0 pb-0">
                    <UserInfo :visitor="visitor"/>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <button class="bottombtn_pc1" @click="runinfo">{{this.$t('runeventinfo_label8')}}</button>
                  </v-col>
                </template>
                <template v-else>
                  <v-col cols="11" align="end" class="mt-3 pa-0 mb-1">
                    <div style="width:100%;color:#627BFF !important" class="text_normal_pc">{{$t('runeventinfo_label20')}}</div>
                  </v-col>
                  <v-col cols="12" align="center" class="mt-0 pt-0 pb-0">
                    <UserInfo :visitor="visitor"/>
                  </v-col>
                  <v-col cols="12" class="pt-0">
                    <button class="bottombtn_pc1" @click="runinfo">{{this.$t('runeventinfo_label8')}}</button>
                  </v-col>
                </template>
              </v-row>            
            </template>

            <template v-else-if="visitor.STATE == 6"> <!-- 환불완료(유료) -->
              <v-row class="mt-3" justify="center">
                <v-col cols="11" align="end" class="mt-3 pa-0 mb-1">
                  <div style="width:100%;color:#627BFF !important" class="text_normal_pc">{{$t('runeventinfo_label19')}}</div>
                </v-col>
                <v-col cols="12" align="center" class="mt-0 pt-0 pb-0">
                  <UserInfo :visitor="visitor"/>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <button class="bottombtn_pc1" @click="runinfo">{{this.$t('runeventinfo_label8')}}</button>
                </v-col>
              </v-row>            
            </template>

          </template>

            </v-col>
          </v-row>
        </v-card>

        
      

    </div>

    </div>
  

</v-container>
</template>

<script>
import UserInfo from './UserInfo.vue'
export default {

  components: {
    UserInfo
  },

  data: () => ({
    tab: null,
    payment_dialog:false,
    dialog: false,
    value: 1,
    bottomstate:true,
    broad:{},
    htmlcontent:"",
    SELECT_OPTION:"",
    visitor:null,
    info:{},
    optionlist:[],
    refundlist:{},
    SELECT_NATION:"",
    nationlist:[],

    boardlist:{},
    schedulelist:{},
    gallist:{},
    maplist:{},
    
    topscroll: 0,

    EVENTCODE:"",
    VISITORCODE:"",

    tablist:[],
    lan_code:0,
    first_flag:true,
  }),

  mounted(){  
    //path를 통해서 들어올 경우 ex) /경로/값
    window.scrollTo(0,0);
    this.EVENTCODE    = this.$route.query.eventcode;
    this.VISITORCODE  = this.$route.query.visitorcode;

    if(this.$route.query.lan_code != null && this.$route.query.lan_code != undefined){
      this.lan_code = Number(this.$route.query.lan_code);
    }else{
      this.lan_code = 0;
    }
    if(this.lan_code == 0){
      this.$i18n.locale = 'ko';
    }else{
      this.$i18n.locale = 'en';
    }
    this.setNationCombo();
    window.addEventListener('scroll', this.handleScroll);

    this.loadpage();
    
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },

  

  methods: {

    setNationCombo(){
      if(this.$i18n.locale == 'ko'){
        this.nationlist = [
            {IDX:0, NAME:"내국인"}
          , {IDX:1, NAME:"외국인"}
        ];
      }else{
        this.nationlist = [
            {IDX:0, NAME:"Korean"}
          , {IDX:1, NAME:"Foreigner"}
        ];
      }
    },

    showpayment_list(){
      //결제하기
      if(this.info.PAY_TYPE == 0){
        //결제 사용안함 상태면 안내 메시지와 함께 결제 거부
        this.$alert(this.$t('runeventinfo_label26')).then(()=>{
        });
        return;
      }else{
        this.payment_dialog = true;
      }
    },

    lanchange(item){
      if(item.value == "ko"){
        this.lan_code = 0;
        this.$i18n.locale = "ko";
      }else{
        this.lan_code = 1;
        this.$i18n.locale = "en";
      }
      this.setNationCombo();
      this.loadpage();
    },

    handleScroll() {
      this.topscroll = 0 - window.scrollY;
      if(this.topscroll < 23){
        this.topscroll = 0;
      }
    },

    runreview(){
      //설문참여
      this.$router.push({path:"/RegReview", query:{eventcode:this.EVENTCODE, visitorcode:this.VISITORCODE, localcode:this.visitor.ISFOREIGNER, lan_code:this.lan_code}});  
    },

    sharekakao(){
      window.Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: this.info.NAME, // 보여질 제목
          description: this.info.DESC1, // 보여질 설명
          imageUrl: this.info.IMAGE,
          link: {
              mobileWebUrl: 'https://fairpass.co.kr/RunEventInfo?eventcode='+this.EVENTCODE,
              webUrl: 'https://fairpass.co.kr/RunEventInfo?eventcode='+this.EVENTCODE
          }
        },
      });
    },
    
    runedit(){
      //수정하기
      this.$confirm(this.$t('runeventinfo_label21')).then(() => {
        this.$router.push({path:"/RegEdit", query:{eventcode:this.EVENTCODE, visitorcode:this.VISITORCODE, lan_code:this.lan_code}});  
      });      
    },

    //등록하기
    goregist(){

      if(this.SELECT_NATION == null || this.SELECT_NATION.length < 1){
        this.$alert(this.$t('runeventinfo_label36'));
        return;
      }

      if(this.SELECT_OPTION == null || this.SELECT_OPTION.length < 1){
        this.$alert(this.$t('runeventinfo_label1'));
        return;
      }
      
      if(this.SELECT_NATION == 1){
        //외국인은 약관동의 없음
        this.$router.push({path:"/RegJoin", query:{eventcode:this.EVENTCODE, localcode:this.SELECT_NATION, optioncode:this.SELECT_OPTION, name:this.info.NAME, lan_code:this.lan_code}});
      }else{
        //내국인은 설정에 따라서
        if(this.info.USE_PRICY == 1){
          this.$router.push({path:"/RegAgree", query:{eventcode:this.EVENTCODE, localcode:this.SELECT_NATION, optioncode:this.SELECT_OPTION, name:this.info.NAME, lan_code:this.lan_code, use_mkt:this.info.USE_MKT}});
        }else{
          this.$router.push({path:"/RegJoin", query:{eventcode:this.EVENTCODE, localcode:this.SELECT_NATION, optioncode:this.SELECT_OPTION, name:this.info.NAME, lan_code:this.lan_code}});
        }
      }

    },    

    goinfo(){
      this.$router.push({path:"RunEventInfo", query:{eventcode:this.EVENTCODE, lan_code:this.lan_code}});
      location.reload();
    },

    //등록정보 확인하기
    runinfo(){

      //모바일에서 QR코드 촬영
      //this.$router.push({path:"QRInput"});
      
      this.$router.push({path:"RunCheckInvit", query:{eventcode:this.EVENTCODE, lan_code:this.lan_code}});
    },

    //입장권 보기
    goqrticket(){
      if(this.info.STATE == 0){
        this.$alert(this.$t('runeventinfo_label22'));
      }else{
        if(this.info.ISPRINT == 1){
          this.$router.push({path:"RunQrTicket", query:{eventcode:this.EVENTCODE, visitorcode:this.VISITORCODE, lan_code:this.lan_code}});
        }else{
          this.$alert(this.$t('runeventinfo_label23'));
        }
      }
    },

    //무료행사 취소하기
    runcancel(){
      this.$confirm(this.$t('runeventinfo_label24')).then(() => {
        this.cancelvisitor();      
      });
    },

    cancelvisitor(){
      this.$http.post(this.$host+'/web/CancelVisitor',{
            EVENT_IDX:this.EVENTCODE
          , VISITOR_IDX:this.VISITORCODE
          , LAN_CODE:this.$i18n.locale
          , ADMIN_IDX:1
      },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
      .then((result)=>{
        if(result.data.resultCode == 0){
          //토큰 저장
          if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
            this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
          }
          if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
            this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
          }
          this.$alert(this.$t('runeventinfo_label25')).then(()=>{
            this.$router.go(this.$router.currentRoute);
          });
        }else if(result.data.resultCode == 2){
          this.$alert(this.$t('regedit_label9')).then(()=>{
            this.$router.replace({path:"RunCheckInvit", query:{eventcode:this.EVENTCODE}});
          });
        }else{
          this.$alert(result.data.resultMsg);
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      
    },

    runpayment(){
      console.log("runpayment...");
      this.$confirm(this.$t('runeventinfo_label27')).then(() => {
          this.showpayment_list();
      });
    },

    //중복결제 체크하고 pg창 띄우기 type = 0 toss, type = 1 eximbay
    checkpayment(type){

        //1.중복결제 체크
        this.$http.post(this.$host+'/ime/RegcheckPaymentVisitor',{
            EVENT_IDX:this.EVENTCODE
          , OPTION_IDX:this.visitor.OPTION_IDX
          , VISITOR_IDX:this.VISITORCODE
          , ADMIN_IDX:1
        })
        .then((result)=>{
          
          if(result.data.resultCode == 0){
            //결제정보가 없다면 결제 가능
            if(result.data.resultData == null || result.data.resultData.length < 1){
              //행사정보 체크
              if(result.data.eventData == null || result.data.eventData.length < 1 ){
                this.$alert(this.$t('runeventinfo_label28')).then(() => {
                  this.$router.go(this.$router.currentRoute);
                });
                return;
              }

              //무료인 경우
              if(result.data.eventData[0].COST_TYPE == 0){
                this.$alert(this.$t('runeventinfo_label29')).then(() => {
                  this.$router.go(this.$router.currentRoute);
                });
                return;
              }

              //결제
              this.payment_dialog = false;

              if(type == 0){
                this.showPayment(result.data.eventData[0]);
              }else{
                this.showPayment_eximbay(result.data.eventData[0]);
              }
              

            }else{
              //이미 결제 정보가 있는 경우
              this.$alert(this.$t('runeventinfo_label30')).then(()=>{
                this.$router.go(this.$router.currentRoute);
              });
              
            }

          }else{
            this.$alert(this.$t('runeventinfo_label31')).then(()=>{
              this.$router.go(this.$router.currentRoute);
            });
            
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
        });  

    },



    showPayment(eventinfo){

      //m_redirect_url : 'https://fairpass.co.kr/ResultPayment?eventcode='+this.EVENTCODE+"&optioncode="+this.visitor.OPTION_IDX+"&visitorcode="+this.VISITORCODE+"&pg=toss&lan_code="+this.lan_code

      window.IMP.request_pay({
          pg: 'uplus', //설정안하면 기본 pg 적용됨
          pay_method: 'card',
          name: eventinfo.EVENT_NAME + this.$t('resultpayment_label7'),
          merchant_uid: 'mid_' + new Date().getTime(),
          amount: eventinfo.COST,
          buyer_email: eventinfo.EMAIL == null || eventinfo.EMAIL == undefined ? '' : eventinfo.EMAIL,
          buyer_name: eventinfo.INFO1,
          buyer_tel: eventinfo.TEL,
          popup: this.$vuetify.breakpoint.smAndDown ? false : true,
          notice_url: 'https://api.fairpass.co.kr/paywebhook?admin=1&eventcode='+this.EVENTCODE+"&optioncode="+this.visitor.OPTION_IDX+"&visitorcode="+this.VISITORCODE+"&pg=toss",
          m_redirect_url : 'https://fairpass.co.kr/ResultPayment?eventcode='+this.EVENTCODE+"&optioncode="+this.visitor.OPTION_IDX+"&visitorcode="+this.VISITORCODE+"&pg=toss&lan_code="+this.lan_code
      }, (result_success) => {
        //console.log("결제 테스트1 : " + JSON.stringify(result_success));
        //중료! => 콜백은 PC에서만 탄다. 모바일은 위의 m_redirect_url로 처리한다.
        if(result_success.success){
          //this.sendSuccess(eventinfo, result_success.imp_uid, result_success.merchant_uid, "toss", 0);
          this.$alert(this.$t('regjoin_label9')).then(()=>{
            this.$router.go(this.$router.currentRoute);
          });
        }else{
          this.$alert(result_success.error_msg);
        }
        
        
      // eslint-disable-next-line no-unused-vars
      }, (result_failure) => {
        //실패시 실행 될 콜백 함수
        this.$alert(this.$t('runeventinfo_label32')).then(()=>{
          this.goHome();
        });
        return;
      });

    },


    //엑심베이 결제
    showPayment_eximbay(eventinfo){
    
      //pg: 'eximbay.36957C2956', //실코드
      //1849705C64
      //m_redirect_url : 'https://fairpass.co.kr/ResultPayment?eventcode='+this.EVENTCODE+"&optioncode="+this.visitor.OPTION_IDX+"&visitorcode="+this.VISITORCODE+"&pg=eximbay&lan_code="+this.lan_code

      window.IMP.request_pay({
          pg: 'eximbay.36957C2956', 
          pay_method: 'card',
          name: eventinfo.EVENT_NAME + this.$t('resultpayment_label7'),
          merchant_uid: 'mid_' + new Date().getTime(),
          amount: eventinfo.COST_USD,
          currency: "USD",
          language: "en",
          buyer_email: eventinfo.EMAIL == null || eventinfo.EMAIL == undefined ? '' : eventinfo.EMAIL,
          buyer_name: eventinfo.INFO1,
          buyer_tel: eventinfo.TEL,
          popup: this.$vuetify.breakpoint.smAndDown ? false : true,
          notice_url: 'https://api.fairpass.co.kr/paywebhook?admin=1&eventcode='+this.EVENTCODE+"&optioncode="+this.visitor.OPTION_IDX+"&visitorcode="+this.VISITORCODE+"&pg=eximbay",
          m_redirect_url : 'https://fairpass.co.kr/ResultPayment?eventcode='+this.EVENTCODE+"&optioncode="+this.visitor.OPTION_IDX+"&visitorcode="+this.VISITORCODE+"&pg=eximbay&lan_code="+this.lan_code
      }, (result_success) => {
        //중료! => 콜백은 PC에서만 탄다. 모바일은 위의 m_redirect_url로 처리한다.
        //console.log("결제 테스트2 : " + JSON.stringify(result_success));
        if(result_success.success){
          this.$alert(this.$t('regjoin_label9')).then(()=>{
            this.$router.go(this.$router.currentRoute);
          });
          //this.sendSuccess(eventinfo, result_success.imp_uid, result_success.merchant_uid, "eximbay", 1);
        }else{
          this.$alert(result_success.error_msg);
        }
        
      // eslint-disable-next-line no-unused-vars
      }, (result_failure) => {
        //실패시 실행 될 콜백 함수
        this.$alert(this.$t('runeventinfo_label32')).then(()=>{
          this.goHome();
        });
        return;
      });

    },

    //전송
    /*
    sendSuccess(eventinfo, iamcode, midcode, pgtype, ctype){
      this.$http.post(this.$host+'/ime/RegInsertPayment',{
          EVENT_IDX:this.EVENTCODE
        , VISITOR_IDX:this.VISITORCODE
        , ADMIN_IDX:1
        , IAMCODE: iamcode
        , MIDCODE: midcode
        , PAYMENTNAME: escape(eventinfo.EVENT_NAME + ' 결제')
        , COST: ctype == 0 ? eventinfo.COST : eventinfo.COST_USD
        , COST_TYPE: ctype
        , NAME: escape(eventinfo.INFO1)
        , TEL: eventinfo.TEL
        , PG: pgtype
        , PAYMETHOD: "card"
        , MSG: escape(this.$t('regjoin_label8'))
      })
      .then((result)=>{
        
        if(result.data.resultCode == 0){
          this.$alert(this.$t('regjoin_label9')).then(()=>{
            this.$router.go(this.$router.currentRoute);
          });
        }else{
          this.$alert(this.$t('regjoin_label10')).then(()=>{
            this.$router.go(this.$router.currentRoute);
          });
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
        if(error.message == 'Request failed with status code 429')
        {
          this.$alert(this.$t('common_msg1'));
        }else{
            this.$alert(error.message);
        }
      });            

    },    
    */

    //유료행사 취소하기 - 환불%
    checkcancelpayment(){

      this.$http.post(this.$host+'/ime/cancelPaymentRate',{
            EVENT_IDX:this.EVENTCODE
          , ADMIN_IDX:1
          , LAN_CODE: this.$i18n.locale
      })
      .then((result)=>{
          if(result.data.resultCode == 0){
            if(result.data.resultData[0].pcode == 0){
              let infomsg = this.$t('runeventinfo_label33');
              this.$confirm(result.data.resultData[0].msg+infomsg).then(() => {
                this.cancelpayment();
              });
            }else{
              this.$alert(result.data.resultData[0].msg);
            }
          }else{
            let infomsg = this.$t('runeventinfo_label34');
            this.$alert(infomsg);
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      

    },

    //환불
    cancelpayment(){
      this.$http.post(this.$host+'/ime/CancelPayment',{
            EVENT_IDX:this.EVENTCODE
          , VISITOR_IDX:this.VISITORCODE
          , OPTION_IDX:this.visitor.OPTION_IDX
          , ADMIN_IDX:1
      },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
      .then((result)=>{
        if(result.data.resultCode == 0){
          //토큰 저장
          if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
            this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
          }
          if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
            this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
          }
          this.$alert(result.data.resultMsg).then(() => {
            this.$router.go(this.$router.currentRoute)
          });

        }else if(result.data.resultCode == 2){
          this.$alert(this.$t('regedit_label9')).then(()=>{
            this.$router.replace({path:"RunCheckInvit", query:{eventcode:this.EVENTCODE}});
          });
        }else{
          this.$alert(result.data.resultMsg).then(() => {
            this.$router.go(this.$router.currentRoute);
          });
        }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      
    },



    daycodeToString(daycode){
      var dayname = "";
      if(daycode == "0"){
        dayname = this.$t('common_msg12');
      }else if(daycode == "1"){
        dayname = this.$t('common_msg13');
      }else if(daycode == "2"){
        dayname = this.$t('common_msg14');
      }else if(daycode == "3"){
        dayname = this.$t('common_msg15');
      }else if(daycode == "4"){
        dayname = this.$t('common_msg16');
      }else if(daycode == "5"){
        dayname = this.$t('common_msg17');
      }else if(daycode == "6"){
        dayname = this.$t('common_msg18');
      }
      return dayname;
    },

    tabchange(tabindex){
  
      
      let tabname = this.tablist[tabindex];
      
      if(tabname == "notice"){
        if(this.boardlist.length == undefined){
          this.loadbroad();
        }
      }else if(tabname == "schedule"){
        if(this.schedulelist.length == undefined){
          this.loadschedule();
        }
      }else if(tabname == "gallery"){
        if(this.gallist.length == undefined){
          this.loadgal();
        }
      }else if(tabname == "map"){
        if(this.maplist.length == undefined){
          this.loadmap();
        }
      }

    },
    
    shownotice(index, item){
      this.broad = item;
      this.broad.INDEX = index+1;
      var html = "";
      html += this.broad.DESC;
      if(item.FILE1 != null && item.FILE1 != undefined && item.FILE1.length > 0){
        
        const last = item.FILE1.split("/")
        item.FILENAME =  decodeURI(last[last.length-1]);

        html += "<a href="+item.FILE1+" download><p>"+this.$t('common_msg19')+item.FILENAME+"</p></a>"
      }
      this.htmlcontent = "<!DOCTYPE HTML><html></html><head><meta name='viewport' content='width=device-width, initial-scale=1'/><style>img{ width:100% }</style></style></head><body>"+html+"</body></html>";
      this.dialog = true;
    },

    clickimg(imgsrc){
      let infomsg = this.$t('common_msg20');
      var w = window.open("", infomsg);
      w.document.write(`<!DOCTYPE html>
      <html><head><meta name='viewport' content='width=device-width, initial-scale=1'/><img src=`+imgsrc+`></img></head><body></body>
      </html>`);        
      w.document.close(); // necessary for IE >= 10
      w.focus();          // necessary for IE >= 10
    },

    showlink(link){
      window.open(link);
    },
    showmap(){
      window.open("https://www.google.com/maps/search/?api=1&query="+this.info.LATITUTE+","+this.info.LONGITUTE);
    },

    loadpage(){

      this.$http.post(this.$host+'/ime/EventDetailAll',{
            EVENT_IDX:this.EVENTCODE
          , ADMIN_IDX:1
          , LAN_CODE: this.first_flag == true ? null : this.$i18n.locale
      })
      .then((result)=>{
        
          if(result.data.resultCode == 0){
            this.info = result.data.resultData[0];

            document.title = this.info.NAME;

            //최초 조회는 행사의 언어 설정에 따른다.
            if(this.first_flag){
              this.lan_code = this.info.USE_LAN;
              if(this.lan_code == 0){
                this.$i18n.locale = 'ko';
              }else{
                this.$i18n.locale = 'en';
              }
              this.first_flag = false;
            }
            
            this.optionlist = result.data.optionData;
            this.refundlist = result.data.refundData;

            //탭정보 지정
            this.tablist.push("info");  //기본정보는 무조건 추가
            if(this.info.USE_NOTICE != undefined && this.info.USE_NOTICE != null && this.info.USE_NOTICE == 1){
              this.tablist.push("notice");
            }
            if(this.info.USE_SCHEDULE != undefined && this.info.USE_SCHEDULE != null && this.info.USE_SCHEDULE == 1){
              this.tablist.push("schedule");
            }
            if(this.info.USE_GALLARY != undefined && this.info.USE_GALLARY != null && this.info.USE_GALLARY == 1){
              this.tablist.push("gallery");
            }
            if(this.info.USE_MAP != undefined && this.info.USE_MAP != null && this.info.USE_MAP == 1){
              this.tablist.push("map");
            }

            //내국인만 사용된 경우
            if(this.info.USE_LOCAL == 1 && this.info.USE_FOREIGNER == 0){
              this.SELECT_NATION = 0;
            }

            //외국인만 사용된 경우
            if(this.info.USE_LOCAL == 0 && this.info.USE_FOREIGNER == 1){
              this.SELECT_NATION = 1;
            }

          }else{
            this.$alert(this.$t('invitation_label33'));
          }

          this.loadvisitor();
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      

    },

    loadvisitor(){

      if(this.VISITORCODE == undefined){
        //visitor 정보 없음 하단 신청하기 구성할 것.
        return;
      }

      this.$http.post(this.$host+'/web/VisitorInfo',{
            EVENT_IDX:this.EVENTCODE
          , VISITOR_IDX:this.VISITORCODE
          , LAN_CODE:this.$i18n.locale
          , ADMIN_IDX:1
      },{headers: { accesstoken:this.$session.get("ACCESSTOKEN"), reflashtoken:this.$session.get("REFLASHTOKEN")}})
      .then((result)=>{
          if(result.data.resultCode == 0){
            
            this.visitor = result.data.resultData[0];

            //토큰 저장
            if(result.data.accesstoken != null && result.data.accesstoken.length > 0){
              this.$session.set("ACCESSTOKEN",   result.data.accesstoken);
            }
            if(result.data.reflashtoken != null && result.data.reflashtoken.length > 0){
              this.$session.set("REFLASHTOKEN",   result.data.reflashtoken);
            }

            if(this.visitor.STATE == 1 && this.visitor.COST_TYPE == 1){
              //바로 결제
              this.runpayment();
            }

          }else if(result.data.resultCode == 2){
            this.$alert(this.$t('regedit_label9')).then(()=>{
              this.$router.replace({path:"RunCheckInvit", query:{eventcode:this.EVENTCODE}});
            });
          }else{
            //모바일 결제 취소시 get으로 넘긴 파라메터가 유실된다. 57154이면 571까지만 돌아옴. 그래서 그냥 다시 백시킴.
            this.$router.replace({path:"RunCheckInvit", query:{eventcode:this.EVENTCODE}});

            //this.$alert(result.data.resultMsg).then(()=>{     });
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      

    },


    loadbroad(){

      this.$http.post(this.$host+'/ime/EventNoticeList',{
          EVENT_IDX:this.EVENTCODE
        , LAN_CODE:this.$i18n.locale
      })
      .then((result)=>{
          if(result.data.resultCode == 0){
            this.boardlist = result.data.resultData;
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      

    },

    loadschedule(){

      this.$http.post(this.$host+'/ime/EventScheduleList',{
        EVENT_IDX:this.EVENTCODE
      })
      .then((result)=>{
          if(result.data.resultCode == 0){
            this.schedulelist = result.data.resultData;
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      

    },

    
    loadgal(){

      this.$http.post(this.$host+'/ime/EventGalleryList',{
          EVENT_IDX:this.EVENTCODE
        , pagerow: 100
        , page: 1
      })
      .then((result)=>{
          if(result.data.resultCode == 0){
            this.gallist = result.data.resultData;
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      

    },
    
    loadmap(){

      this.$http.post(this.$host+'/ime/EventMapList',{
          EVENT_IDX:this.EVENTCODE
      })
      .then((result)=>{
        
          if(result.data.resultCode == 0){
            this.maplist = result.data.resultData;
          }
      })
      // eslint-disable-next-line no-unused-vars
      .catch((error)=>{
          if(error.message == 'Request failed with status code 429')
          {
            this.$alert(this.$t('common_msg1'));
          }else{
              this.$alert(error.message);
          }
      });      

    }


  },

}
</script>

<style scoped>

/* 옵션 선택창 */
.fixed_info_150{
  position: fixed;
  z-index:10;
  width:455px;
  min-width: 455px;
}

.fixed_info_50{
  position: fixed;
  top: 50px;
  right: 90px;
  z-index:9999;
  width: 630px;
}

.bottombtn_pc1{
  width:100%; 
  height:58px;
  font-size:16px; 
  font-family: 'NotoSansKR-Medium' !important;
  text-align: center !important;
  color: #fff !important;
  border-radius: 0px !important;
  background-color: #627BFF !important;
}
.bottombtn_pc2{
  width:100%; height:58px;
  font-size:16px;
  font-family: 'NotoSansKR-Medium' !important;
  text-align: center !important;
  color: #525252 !important;
  border-radius: 0px !important;
  background-color: #E8E8E8 !important;
}

.bottombtn{
  width:100%; 
  height:44px;
  font-size:14px; 
  font-family: 'NotoSansKR-Medium' !important;
  text-align: center !important;
  color: #fff !important;
  border-radius: 0px !important;
  background-color: #627BFF !important;
}

.bottombtn2{
  width:100%; 
  height:44px;
  font-size:14px; 
  font-family: 'NotoSansKR-Medium' !important;
  text-align: center !important;
  color: #525252 !important;
  border-radius: 0px !important;
  background-color: #E8E8E8 !important;
}

.bottombtn:hover{
  background-color: #2d2f77 !important;
}

.v-text-field--outlined >>> fieldset {
  border-color: #E5E5E5;
}

.tabletitle{
  width:106px !important;
  min-width: 106px;
  font-size:14px;
  color:#3D3D3D;
  background:#F6F6F6;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-width: 0px;
  white-space: pre-wrap;
}
.tabletitle_mb{
  width:20%;
  min-width: 70px;
  font-size:14px;
  color:#3D3D3D;
  background:#F6F6F6;
  border-left-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-width: 0px;
  white-space: pre-wrap;
  padding-top: 5px;
  padding-bottom: 5px;
}

.tabledesc{
  font-size:14px;
  color:#3D3D3D;
  border-right-width: 1px;
  border-left-width: 0px;
  border-bottom-width: 1px;
  border-top-width: 0px;
  white-space: pre-wrap;
  padding: 5px 0px 5px 0px;
}
.tabledesc_md{
  width:70%;
  font-size:14px;
  color:#3D3D3D;
  border-right-width: 1px;
  border-left-width: 0px;
  border-bottom-width: 1px;
  border-top-width: 0px;
  white-space: pre-wrap;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.tabledesc_md2{
  width:70%;
  font-size:14px;
  color:#3D3D3D;
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-width: 0px;
  white-space: pre-wrap;
  padding-left: 10px;
  padding-top: 7px;
  padding-bottom: 7px;
}


.table100_md{
  font-size:14px;
  color:#3D3D3D;
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-width: 1px;
  white-space: pre-wrap;
  padding-top: 7px;
  padding-bottom: 7px;
}


.tablerefund_md{
  font-size:14px;
  color:#3D3D3D;
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-width: 0px;
  white-space: pre-wrap;
}


.tabledesc2{
  font-size:15px;
  color:#3D3D3D;
  white-space: pre-wrap;
  padding: 10px 0px 10px 0px;
}

.tabledesc_date{
  font-size:14px;
  color:#929292;
  white-space: pre-wrap;
  padding: 10px 0px 10px 0px;
}

.tabledesc_area{
  font-size:15px;
  color:#3D3D3D;
  white-space: pre-wrap;
}


table{
  border-radius: 20px;
  border-collapse: separate; 
  border-spacing:0;
  border: 0px solid #DADADA;
}

td {
  border: 1px solid #DADADA;
}


.text_normal_pc{
  font-size:14px !important;
  color:#3D3D3D !important;
  font-family: 'NotoSansKR-Medium' !important;
}

.text_normal_mb{
  font-size:14px;
  color:#3D3D3D;
}

.top_blue_btn{
  width: 100%;
  background-color: #627bff;
  color:#ffffff;
  font-size: 13px;
  font-family: 'NotoSansKR-Regular';
  height: 40px;
  text-align: center;
  line-height: 40px;
}

.vtab_text{
  font-size: 14px;
  font-family: 'NotoSansKR-Medium';
  color: #3D3D3D !important;
  text-transform: none;
}

.vtab_text_mb{
  font-size: 14px;
  font-family: 'NotoSansKR-Medium';
  color: #3D3D3D !important;
  text-transform: none;
}

.v-tab--active{
  color: #4364FF !important;
}


.btncursor{
  cursor: pointer;
}

.option_title{
  font-size: 20px;
  color: #515151;
  font-family: 'NotoSansKR-Medium' !important;
}

.event_title{
  font-size:18px;
  color:#3D3D3D;
  font-weight:bold;
  margin-left:15px;
  margin-right:15px;
  font-family: 'NotoSansKR-Medium' !important;
}

.event_title_mb{
  font-size:16px;
  color:#3D3D3D;
  font-family: 'NotoSansKR-Medium' !important;
  font-weight:bold;
}

.event_desc1{
  font-size:14px;
  color:#3D3D3D;
  white-space:pre-wrap;
  margin-left:15px;
  margin-right:15px;
  font-family: 'NotoSansKR-Medium' !important;
}

.event_desc1_mb{
  font-size:13px;
  color:#3D3D3D;
  font-family: 'NotoSansKR-Medium' !important;
  white-space:pre-wrap;
}

.event_desc2{
  font-size:14px;
  color:#3D3D3D;
  white-space:pre-wrap;
  margin-left:15px;
  margin-right:15px;
  font-family: 'NotoSansKR-Regular' !important;
}

.event_desc2_mb{
  font-size:13px;
  color:#3D3D3D;
  font-family: 'NotoSansKR-Regular' !important;
  white-space:pre-wrap;
}

.event_map_title{
  font-size:16px;
  color:#627BFF;
  font-family: 'NotoSansKR-Medium' !important;
  white-space:pre-wrap;
}

.info_blue_text{
  font-size:14px !important;
  color:#627BFF !important;
  font-family: 'NotoSansKR-Regular' !important;
  white-space:pre-wrap;
}

  /* v-select outlinned border 설정 */
  .v-text-field--outlined >>> fieldset {
    border-radius: 0px;
    border: 1px;
    border-color: #DADADA;
  }
  /* v-select 팝업 선택한 아이템 텍스트 컬러 */
  .v-list .v-list-item--active .v-list-item__title {
    color: #627BFF !important;
  }
  /* v-select 팝업 텍스트 컬러 */
  .v-list .v-list-item__title {
    color: #6a6a6a !important;
  }

.select_text{
  font-size:14px; 
  font-family: 'NotoSansKR-Regular' !important;
  font-weight: 100;
  color: #4364FF !important;
}


</style>
