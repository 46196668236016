import Vue from 'vue'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'

//라이브러리
import axios from 'axios'               //http library
import querystring from 'querystring'   //axios랑 함께 써야하는 라이브러리 (post 파라메터 전송)

import VueSimpleAlert from "vue-simple-alert";
Vue.prototype.$alert_option = {reverseButtons: true, allowEnterKey:false, confirmButtonText: "확인", cancelButtonText:"취소"}; 
Vue.use(VueSimpleAlert, Vue.prototype.$alert_option);  //엔터를 처도 팝업창 클로즈 안함. (sweetalert2 라이브러리 옵션 참고)

import VueSessionStorage from 'vue-sessionstorage'  //저장소

//다국어
import { i18n } from "@/locales/i18n";

Vue.use(VueSimpleAlert);
Vue.use(VueSessionStorage);


//index.html파일에 kakao sdk cdn으로 임포트함.
window.Kakao.init("3f5b3cca648b31e7445790fa826359f8");  //fairpass

//카카오채널
//import VueKakaoSdk from 'vue-kakao-sdk'
//const apiKey = '3f5b3cca648b31e7445790fa826359f8'
//Vue.use(VueKakaoSdk, { apiKey }) // apiKey 를 반드시 입력해줘야한다.

//화면단
import App        from './App.vue'
import Join       from './components/Join.vue'
import FairPass   from './components/FairPass.vue'

import RegAgree   from './components/RegAgree.vue'
import RegJoin    from './components/RegJoin.vue'
import RegReview  from './components/RegReview.vue'
import RunCheckInvit    from './components/RunCheckInvit.vue'
import RunQrTicket   from './components/RunQrTicket.vue'
import RunEventInfo   from './components/RunEventInfo.vue'
import RegEdit   from './components/RegEdit.vue'
import ShowTicket   from './components/ShowTicket.vue'
import ShowAvg   from './components/ShowAvg.vue'
import ResultPayment   from './components/ResultPayment.vue'

import AppAreaList from './components/AppAreaList.vue'
import AppLogin from './components/AppLogin.vue'
import AppSelect from './components/AppSelect.vue'
import AppEventComout from './components/AppEventComout.vue'

import PartEventInfo   from './components/PartEventInfo.vue'
import PartEventComout from './components/PartEventComout.vue'
import PartEventJoin from './components/PartEventJoin.vue'

import EmailBlock from './components/EmailBlock.vue'

import Invitation from './components/Invitation.vue'
import InvitResult from './components/InvitResult.vue'
import CheckInvit from './components/CheckInvit.vue'
import QrTicket   from './components/QrTicket.vue'
import Agree      from './components/Agree.vue'
import NotFound   from './components/NotFound.vue'

import QRInput from './components/QRInput.vue'
import QRResult from './components/QRResult.vue'

import HomeSVG from './svg/HomeSVG.vue'
import LanSVG from './svg/LanSVG.vue'
import HomeBlackSVG from './svg/HomeBlackSVG.vue'
import YoutubeSVG from './svg/YoutubeSVG.vue'
import MapSVG from './svg/MapSVG.vue'
import ShareSVG from './svg/ShareSVG.vue'

//커스텀 컴포넌트 등록
Vue.component("homeicon",     HomeSVG);
Vue.component("lanicon",      LanSVG);
Vue.component("homeblackicon",      HomeBlackSVG);
Vue.component("youtubeicon",      YoutubeSVG);
Vue.component("mapicon",      MapSVG);
Vue.component("shareicon",      ShareSVG);

//전역변수
Vue.prototype.$http = axios

Vue.prototype.$qstr = querystring
//Vue.prototype.$host = "http://192.168.0.39:8080";   //테스트 서버
//Vue.prototype.$host = "http://pairpass-lb-1605632864.ap-northeast-2.elb.amazonaws.com"; 
Vue.prototype.$host = "https://api.fairpass.co.kr";   //실서버
Vue.prototype.$hostname = window.location.hostname;   //호스트네임
//Vue.prototype.$hostname = "pictcloud.com";   //호스트네임

//국제화(다국어 작업)
Vue.prototype.$lanlist = [{name:'Korean',value:'ko'},{name:'English',value:'en'}];

//아임포트 회원가입 후 발급된 가맹점 고유 코드를 사용해주세요. 예시는 KCP공식 아임포트 데모 계정입니다.
var IMP = window.IMP;
IMP.init('imp85952917');
//Vue.use(IMP, 'imp85952917');
//Vue.IMP().load();

//국제화(다국어 작업)
Vue.prototype.$lanlist = [{name:'Korean',value:'ko'},{name:'English',value:'en'}];
//개발중에 업데이트 요청오면 false로 바꿔서 업데이트 해야한다.
Vue.prototype.$uselan = true;


//플러그인 사용
Vue.use(VueRouter)

//라우터 인스턴스 생성
const router = new VueRouter({
  mode:'history', //history모드 중요함. 이걸로 해야함.
  routes : [
      {path : "/",                  component:FairPass}
    , {path : "",                   component:FairPass}
    
    //비회원 접수
    , {path : "/RegAgree",             component:RegAgree}
    , {path : "/RegAgree/:eventcode/:optioncode/:name", component:RegAgree}
    , {path : "/RegJoin",              component:RegJoin}
    , {path : "/RegJoin/:eventcode/:optioncode/:name",  component:RegJoin}
    , {path : "/RegReview",              component:RegReview}
    , {path : "/RegReview/:eventcode/:visitorcode",  component:RegReview}
    , {path : "/RunCheckInvit",         component:RunCheckInvit}
    , {path : "/RunCheckInvit/:eventcode/:optioncode/:name/:tel",  component:RunCheckInvit}
    , {path : "/RunQrTicket",          component:RunQrTicket}
    , {path : "/RunQrTicket/:eventcode/:visitorcode", component:RunQrTicket}
    , {path : "/RunEventInfo",          component:RunEventInfo}
    , {path : "/RunEventInfo/:eventcode/:visitorcode", component:RunEventInfo}
    , {path : "/RegEdit",          component:RegEdit}
    , {path : "/RegEdit/:eventcode/:visitorcode", component:RegEdit}
    , {path : "/ShowTicket",          component:ShowTicket}
    , {path : "/ShowTicket/:token", component:ShowTicket}
    , {path : "/ShowAvg",          component:ShowAvg}
    , {path : "/ShowAvg/:token", component:ShowAvg}
    , {path : "/ResultPayment/", component:ResultPayment}

    //QR앱
    , {path : "/AppAreaList",                     component:AppAreaList}
    , {path : "/AppLogin",                        component:AppLogin}
    , {path : "/AppLogin/:eventcode",             component:AppLogin}
    , {path : "/AppSelect",                       component:AppSelect}
    , {path : "/AppSelect/:adminidx/:comidx/:eventcode/:placeidx",  component:AppSelect}
    , {path : "/AppEventComout",                  component:AppEventComout}
    , {path : "/AppEventComout/:adminidx/:comidx/:eventcode/:placeidx",  component:AppEventComout}

    //참여기업
    , {path : "/PartEventInfo",              component:PartEventInfo}
    , {path : "/PartEventInfo/:eventcode",   component:PartEventInfo}
    , {path : "/PartEventComout",            component:PartEventComout}
    , {path : "/PartEventJoin",              component:PartEventJoin}
    , {path : "/PartEventJoin/:eventcode/admin_idx/com_idx",   component:PartEventJoin}

    //초청장
    , {path : "/Invitation",        component:Invitation}
    , {path : "/Invitation/:code",  component:Invitation}
    , {path : "/Agree",             component:Agree}
    , {path : "/Agree/:code/:name", component:Agree}
    , {path : "/Join",              component:Join}
    , {path : "/Join/:code/:name",  component:Join}
    , {path : "/InvitResult",       component:InvitResult}
    , {path : "/InvitResult/:code", component:InvitResult}

    , {path : "/EmailBlock",       component:EmailBlock}
    , {path : "/EmailBlock/:mail", component:EmailBlock}

    , {path : "/QRInput",          component:QRInput}
    , {path : "/QRResult",         component:QRResult}

    //아래 페이지 안쓸듯.. 초대권을 비회원 페이지로 엮어서
    , {path : "/CheckInvit",        component:CheckInvit}
    , {path : "/CheckInvit/:code",  component:CheckInvit}
    , {path : "/QrTicket",          component:QrTicket}
    , {path : "/QrTicket/:printqr/:printnumber/:tel/:eventname", component:QrTicket}

    , {path : "*",                  redirect:"/404"}
    , {path : "/404",               component:NotFound}
  ],
  
})


Vue.config.productionTip = false

new Vue({
    vuetify
  , i18n
  , render: h => h(App)
  , router
}).$mount('#app')
